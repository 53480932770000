<template>
  <div class="detail" v-if="ready && tableDataRow">
    <md-dialog :md-active.sync="showHtmlDialog">
      <md-dialog-title>{{htmlDialogData.title}}</md-dialog-title>
      <md-dialog-content v-html="htmlDialogData.html"></md-dialog-content>

      <md-dialog-actions>
        <md-button class="md-primary" @click="showHtmlDialog = false">{{$t('actions.close')}}</md-button>
      </md-dialog-actions>
    </md-dialog>

    <v-dialog
      v-model="showReportsDialog"
      persistent
      no-click-animation
      scrollable
      fullscreen
    >
      <v-card v-if="showReportsDialog" style="height: 80vh; display: flex; flex-direction: column;">
        <v-card-title class="text-h6 white--text primary">
          {{ report.label }}
        </v-card-title>

        <v-card-text class="pa-0" style="display: flex; flex: 1 1 0;">
          <i-frame :content="reportDialogRenderElement"></i-frame>
        </v-card-text>

        <v-card-actions style="border-top: 1px solid rgb(233, 233, 233);">
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="createPDF">
                <v-list-item-content>PDF</v-list-item-content>
                <v-list-item-action>
                  <v-btn icon>
                    <v-icon>mdi-file-pdf-box</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-spacer></v-spacer>
          <v-btn text @click="closeReportDialog">{{$t('actions.close')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <md-toolbar class="detail-toolbar" md-elevation="0">
      <md-button class="md-icon-button" @click="onClose()">
        <md-icon>close</md-icon>
      </md-button>
      <span class="text-smaller text-row">
        Row {{tableDataRow.__num}}
      </span>
      <span v-if="singleCaseView" class="text-smaller ml-1 label-span">
        ({{ singleCaseView.label }})
      </span>
      <div class="md-toolbar-section-end">
        <span v-if="isMultiLangVerbatim" style="width: 100px;">
          <md-field>
            <md-select :value="language" @md-selected="setLanguage">
              <md-option v-for="lang in languages" :value="lang" :key="lang">{{getLangName(lang)}}</md-option>
            </md-select>
          </md-field>
        </span>
        <md-menu v-if="($store.state.view.singleCaseViews && $store.state.view.singleCaseViews.length > 0) || (audioData && audioData.length > 0)" md-size="small">
          <md-button class="md-icon-button" md-menu-trigger>
            <md-icon>more_vert</md-icon>
          </md-button>
          <md-menu-content>
            <md-menu-item @click="setSingleCaseView(null)" v-if="verbatimVariable">
              <span class="md-list-item-text">Verbatim</span>
            </md-menu-item>
            <md-menu-item
              v-for="singleCaseView in ($store.state.view.singleCaseViews || [])"
              :key="singleCaseView.name"
              @click="setSingleCaseView(singleCaseView)"
            >
              <span class="md-list-item-text">{{ singleCaseView.label }}</span>
            </md-menu-item>
            <md-menu-item @click="showAudio()" v-if="audioData != null">
              <span class="md-list-item-text">Audio</span>
            </md-menu-item>
          </md-menu-content>
        </md-menu>
      </div>
    </md-toolbar>

    <div ref="detailInner" class="detail-inner">
      <template v-if="isAudio">
        <audioplayer ref="audioplayer" class="audioplayer-detail" :audioData="audioData"></audioplayer>
      </template>
      <template v-if="isReport">
        <div style="background: #fff; padding: 0px 20px; text-align: right;">
          <v-btn
            icon
            color="secondary"
            @click="createPDF"
          >
            <v-icon dark>
              mdi-file-pdf-box
            </v-icon>
          </v-btn>
        </div>
        <i-frame :content="reportRenderElement" :noPointerEvents="noPointerEventsOnIFrame"></i-frame>
      </template>
      <template v-if="isReports">
        <v-list>
          <v-list-item v-for="(report, index) in reports" :key="index" @click="openReportDialog(report)">
            <v-list-item-content>{{ report.label }}</v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
      <template v-else-if="singleCaseView">
        <div class="detail-container">
          <!--<single-case-view :singleCaseView="singleCaseView" :dataset="result"></single-case-view>-->
          <div v-for="(val, label, index) in singleCaseData" :key="index">
            <template v-if="val.display === 'multiple' || (val.data && val.data.value !== undefined)">
            <div class="detail-item">
              <div class="md-caption mb-1 text-smaller center-vertical">
                <span>{{ label }}</span>
                <div v-if="val.display === 'single' && val.data.type === 'html'" class="center-vertical" style="height: 0;">
                  <md-button class="md-icon-button" @click="openHtmlInWindow(val.data.value)">
                    <md-icon>open_in_new</md-icon>
                    <md-tooltip md-direction="top">{{$t('tooltips.OPEN_IN_NEW_WINDOW')}}</md-tooltip>
                  </md-button>
                </div>
              </div>
              <!--<div v-if="_isArray(val)" class="md-body-1 text-smaller">{{ val.join(', ') }}</div>-->
              <div v-if="val.display === 'multiple'">
                <div v-for="(v, k) in val.data" :key="k">
                  <div v-if="v.type === 'html'" class="detail-item">
                    <md-button class="md-raised md-primary" @click="htmlViewDialogSingleCase(label, v.value)">{{$t('actions.show')}}</md-button>
                  </div>
                  <div v-else class="mt-3 mb-1">
                    <div class="md-caption mb-1 text-smaller">{{ k }}</div>
                    <div class="md-body-1 text-smaller" v-tq-results-cell-value="v"></div>
                  </div>
                </div>
              </div>
              <div v-else-if="val.display==='single'" v-tq-results-cell-value="val.data" class="md-body-1 text-smaller" style="white-space: pre-line;"></div>
            </div>
            <md-divider />
            </template>
          </div>
        </div>
      </template>
      <template v-else-if="isHtmlVerbatim">
        <div class="detail-container">
          <div class="detail-heading">
            {{verbatimVariable.label}}
          </div>

          <div v-for="(codingvar, index) in verbatimVariable.codingvars" :key="index" class="detail-item">
            <div class="md-caption mb-1 text-smaller">{{codingvar.label}}</div>
            <div class="md-body-1 text-smaller">{{tableDataRow[verbatimVariable.name + ':' + codingvar.name]}}</div>
          </div>

          <div class="detail-item">
            <md-button class="md-raised md-primary" @click="htmlViewDialog">{{$t('actions.show')}}</md-button>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="detail-container">
          <div class="detail-item">
            <div class="md-caption mb-1 text-smaller">Verbatim</div>
            <div class="md-body-1 text-smaller">{{getVerbatim()}}</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { t } from '@/i18n'

import ViewModel from '@/models/view'
import api from '@/services/api'
import vueTemplate from '@/services/vueTemplate'

const isHtml = function(str) {
  return (str && _.isString(str)) ? str.search(/^<([A-Z][A-Z0-9]*)\b[^>]*>(.*?)<\/\1>$/i) !== -1 : false
}

export default {
  label: 'ResultsDetail',
  props: {
    onClose: Function,
    tableDataRow: Object,
    verbatim: String,
    language: {
      type: String,
      default: 'org'
    },
    isRezising: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    isReport() {
      return !!this.singleCaseView && this.singleCaseView.type == 'report'
    },
    isReports() {
      return !!this.singleCaseView && this.singleCaseView.type == 'reports'
    }
  },
  data: () => ({
    audioData: null,
    compiled: null,
    html: null,
    htmlDialogData: {},
    htmlWindow: null,
    isAudio: null,
    isAudioEnabled: false,
    isHtmlVerbatim: false,
    isMultiLangVerbatim: false,
    languagess: null,
    noPointerEventsOnIFrame: false,
    previewVueInstance: null,
    ready: false,
    report: null,
    reportDialogRenderElement: null,
    reportRenderElement: null,
    reports: null,
    result: null,
    singleCaseView: null,
    singleCaseViewVueInstance: null,
    singleCaseData: null,
    showHtmlDialog: false,
    showReportsDialog: false,
    skipDefaultLanguage: false,
    variablesNameMap: null,
    verbatimVariable: null,
    //language: null
  }),
  methods: {
    checkMultiLangObject(objOrStr) {
      return objOrStr && !_.isString(objOrStr) && objOrStr.hasOwnProperty('org')
    },
    async closeReportDialog() {
      this.showReportsDialog = false
    },
    collectLanguages(obj, languages) {
      if (languages == null) {
        languages = new Set()
      }
      if (obj != null) {
        Object.entries(obj).forEach(entry => {
          if (entry[0] === 'verbatim') {
            if (this.checkMultiLangObject(entry[1])) {
              Object.keys(entry[1]).forEach(key => languages.add(key))
            }
          } else if (typeof entry[1] === 'object') { // true for Object and Array
            this.collectLanguages(entry[1], languages)
          }
        })
      }
      return languages
    },
    async createPDF() {
      const response = await api.call('getSourceViewReportPdf', {
        sourceName: this.$store.state.source.name,
        viewName: this.$store.state.view.name,
        resultId: this.result._id,
        reportTemplateId: this.reportTemplate._id
      })

      const arr = response.data

      const int8Array = new Int8Array(arr)
      const blob = new Blob([int8Array])
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')

      a.href = url
      a.download = `${this.reportTemplate.name}.pdf`
      
      document.body.appendChild(a)
      
      a.click()
      
      document.body.removeChild(a)
      
      URL.revokeObjectURL(url)
    },
    getLangName(lang) {
      switch (lang) {
        case 'en':
          return 'EN'
        case 'de':
          return 'DE'
        case 'org':
          return t('labels.original')
        default:
          return lang
      }
    },
    getMultilang(obj) {
      return this.checkMultiLangObject(obj) ? (obj[this.language] || obj['org']) : obj
    },
    getVerbatim() {
      let verbatim = this.tableDataRow.verbatim
      return this.isMultiLangVerbatim ? (verbatim[this.language] || verbatim['org']) : verbatim
    },
    htmlViewDialog() {
      this.htmlDialogData = {
        title: this.verbatimVariable.label,
        html: this.tableDataRow.verbatim
      }
      this.showHtmlDialog = true
    },
    htmlViewDialogSingleCase(key, value) {
      this.htmlDialogData = {
        title: key,
        html: value
      }
      this.showHtmlDialog = true
    },
    initTableDataRow(tableDataRow) {
      // check if verbatim is in multiple languages
      //this.isMultiLangVerbatim = this.checkMultiLangObject(this.tableDataRow.verbatim)

      let languages = Array.from(this.collectLanguages(tableDataRow))

      if (languages.length === 0) {
        this.isMultiLangVerbatim = false
        this.languages = null
      } else {
        this.isMultiLangVerbatim = true
        this.languages = languages

        if (!this.language || languages.indexOf(this.language) === -1) {
          let defaultLang = this.$store.state.defaultVerbatimLang
          this.skipDefaultLang = true
          this.setLanguage(languages.indexOf(defaultLang) === -1 ? 'org' : defaultLang)
        }
      }

      // check if verbatim starts with an HTML tag and ends with the same tag name
      this.isHtmlVerbatim = isHtml(this.tableDataRow.verbatim)

      this.result = tableDataRow.__result
      this.html = this.tableDataRow.html
      this.audioData = this.isAudioEnabled ? this.result && this.result._description && this.result._description.audio : null
      this.updateSingleCaseData()
    },
    openHtmlInWindow(html) {
      // If the details window is open, close it, because otherwise the html will get appended to the existing html
      let htmlWindow = this.htmlWindow
      if (htmlWindow) {
        htmlWindow.close()
      }
      let windowWidth = 800
      let windowHeight = 600
      // Determine center position of popup. This should even work with dual screen configurations
      let dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : screen.left
      let dualScreenTop = window.screenTop !== undefined ? window.screenTop : screen.top
      let containerWidth = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width
      let containerHeight = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height
      let left = (containerWidth / 2 - windowWidth / 2) + dualScreenLeft
      let top = (containerHeight / 2 - windowHeight / 2) + dualScreenTop
      // Open blank window. Note that using "about:blank" as the url sometimes prevents the html from showing correctly.
      htmlWindow = window.open('', "_resultDetail", 'width=' + windowWidth + ', height=' + windowHeight + ', top=' + top + ', left=' + left + '')
      // Create and set window content
      htmlWindow.document.write(html)
      htmlWindow.document.body.style.cssText = "font-family: Roboto; font-size: 14px; line-height: 1.2; padding: 8px 16px;"
      htmlWindow.document.title = "Result Detail"
      // Put focus on details Window
      if (htmlWindow.focus) {
        htmlWindow.focus()
      }
      // Remember details window
      this.htmlWindow = htmlWindow
    },
    async openReportDialog(report) {
      this.reportTemplate = (await api.call('getReportTemplate', { id: report.id })).data
      let vueData = this.reportTemplate.vue

      this.report = report

      this.reportDialogRenderElement = vueTemplate.createVueElem(
        vueData.template,
        this.result,
        vueData.translations,
        vueData.options
      )
      
      this.showReportsDialog = true
    },
    setLanguage(lang) {
      this.$emit('update:language', lang)
    },
    setSingleCaseView(singleCaseView) {
      this.isAudio = false
      this.singleCaseView = singleCaseView
      this.updateSingleCaseData()
    },
    showAudio() {
      this.isAudio = true
    },
    async updateSingleCaseData() {
      let singleCaseView = this.singleCaseView

      if (singleCaseView) {
        if (singleCaseView.type == "report") {
          this.reportTemplate = (await api.call('getReportTemplate', { id: singleCaseView.id })).data
          let vueData = this.reportTemplate.vue

          this.previewVueInstance = vueTemplate.createVueInstance(
            vueData.template,
            this.result,
            vueData.translations,
            vueData.options
          )
          
          this.reportRenderElement = this.previewVueInstance.$mount().$el
        } else if (singleCaseView.type == "reports") {
          this.reports = singleCaseView.reports
        } else if (singleCaseView.variables) {
          this.singleCaseData = {}
          
          singleCaseView.variables.forEach((variableName) => {
            let variable = this.variablesNameMap[variableName]
            
            if (variable) {
              let val = _.get(this.result, variableName)

              let fractions = val && val.fractions
              if (fractions) {
                fractions.forEach((fraction, i) => {
                  let fractionData = {}

                  for (let key in fraction) {
                    let fractionValue = fraction[key]

                    if (key !== 'verbatim') {
                      let fractionVariable = this.variablesNameMap[variableName + ':' + key]

                      if (fractionVariable) {
                        fractionData[fractionVariable.label] = { value: fractionValue, type: fractionVariable.type }
                      }
                    }
                  }

                  if (fraction.verbatim) {
                    let fractionValue = this.getMultilang(fraction.verbatim)

                    fractionData.verbatim  = { value: fractionValue, type: isHtml(fractionValue) ? 'html' : 'text' }
                  }

                  this.singleCaseData[variable.label + '( ' + (i + 1) + ' / ' + fractions.length + ')'] = { display: 'multiple', data: fractionData }
                })
              } else {
                this.singleCaseData[variable.label] = { display: 'single', data: { value: val, type: variable.type } }
              }
            } else {
              console.error("Variable is not defined: " + variableName)
            }
          })
        } else {
          this.singleCaseData = null
        }
      }
    }
  },
  watch: {
    language(lang) {
      if (!this.skipDefaultLang) {
        this.$store.commit('setDefaultVerbatimLang', lang)
      }
      this.skipDefaultLang = false
      this.updateSingleCaseData()
    },
    tableDataRow(tableDataRow) {
      this.initTableDataRow(tableDataRow)
    },
    isAudio(b) {
      // Make sure audio stops paying
      if (!b && this.$refs.audioplayer) {
        this.$refs.audioplayer.stopAmplitude()
      }
    },
    isRezising(isRezising) {
      this.noPointerEventsOnIFrame = isRezising
    }
  },
  mounted() {
    let variablesNameMap = {}

    this.$store.state.view.variables.forEach(variable => {
      variablesNameMap[variable.name] = variable
      if (variable.codingvars) {
        (variable.codingvars || []).forEach(codingvar => {
          variablesNameMap[variable.name + ':' + codingvar.name] = codingvar
        })
      }
    })
    this.variablesNameMap = variablesNameMap
    this.verbatimVariable = this.verbatim && this.variablesNameMap[this.verbatim]

    // only show audio player if enabled in settings
    let viewSettings = ViewModel.getSettings(this.$store.state.view)
    this.isAudioEnabled = viewSettings && (viewSettings.audio === true)

    // full result from server
    this.initTableDataRow(this.tableDataRow)

    if (!this.verbatimVariable && this.$store.state.view.singleCaseViews && this.$store.state.view.singleCaseViews.length > 0) {
      this.setSingleCaseView(this.$store.state.view.singleCaseViews[0])
    }

    this.ready = true
  }
}
</script>

<style lang="scss" scoped>

.center-vertical {
  display: flex;
  align-items: center;
}

.detail {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  overflow: hidden;
  border-left: 1px solid rgba(34, 36, 38, 0.15);
  width: 100%;
  min-width: 300px;

  .detail-toolbar {
    padding: 0 5px;
    flex-flow: row;
    flex: 0 0 52px;
    min-height: 52px;
    border-top: 1px solid rgba(34, 36, 38, 0.15);
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
    background: #fff;

    .text-row {
      color: $primary;
      font-size: 16px;
      font-weight: 500;
    }

    >span {
      white-space: nowrap;
    }

    .label-span {
      min-width: 0;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .detail-container {
    overflow: auto;
  }

  .detail-heading {
    font-size: 18px;
    padding: 16px 24px;
  }

  .detail-item {
    flex: 1 0 0;
    padding: 16px 24px;
  }

  .detail-inner {
    flex: 1 0 0;
    min-height: 0;
    min-width: 400px;
    display: flex;
    flex-direction: column;
  }
}


.audioplayer-detail {
  min-height: 0;
}

</style>
