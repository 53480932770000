import Vue from 'vue'
import dateFormat from 'dateformat'
import _ from 'lodash'
import VueI18n from 'vue-i18n'
import templateFunctions from "@/services/templateFunctions";

const execEval = options => {
  // disabled because of eval() security vulnerability
  return {}

  // keep code in case options should be used
  //
  // let componentOptions = {}
  // if (options) {
  //   eval(`componentOptions = ${options}`)
  // }
  //
  // return componentOptions*/
}

const vueTemplate = {
  createVueElem(template, dataset, translations, options, errorHandler) {
    let vueInstance = vueTemplate.createVueInstance(template, dataset, translations, options, errorHandler)
    let element = vueInstance.$mount().$el.cloneNode(true)

    vueInstance.$destroy()

    return element
  },

  createVueInstance(template, dataset, translations, options, errorHandler) {
    // the <style tag can not be parsed by Vue so it's replaced by <v-style> tag which is handled
    // by a custom component
    template = template.replace(
      /<style([^>]*)>([\s\S]*?)<\/style>/g,
      '<v-style$1>$2</v-style>'
    )

    errorHandler = Object.assign({
      warning: () => {},
      error: () => {}
    }, errorHandler || {})

    dataset = dataset || {}

    let componentOptions = options ? execEval(options) : {}

    const i18n = new VueI18n({
      locale: 'en',
      messages: translations
    })

    let vueOptions = {
      template: template || '',
      components: {
        "v-style": {
          render(createElement) {
            return createElement('style', this.$slots.default)
          } 
        }
      },
      data: () => {
        return {
          dataset: dataset,
          dateFormat: dateFormat
        }
      },
      methods: {
        get(path, locale) {
          return _.get(dataset, locale ? `${path}.${locale}`: path)
        },
        getDate() {
          return templateFunctions.getDate
        },
        surveyLink() {
          return '<< surveyLink() >>'
        },
        surveyUrl() {
          return '<< surveyUrl() >>'
        },
        t(path, locale, options) {
          i18n.locale = locale

          return i18n.t(path, options)
        }
      }
    }

    if (componentOptions.data) {
      let data = Object.assign(vueOptions.data(), componentOptions.data())
      
      vueOptions.data = () => data

      delete componentOptions.data
    }

    if (componentOptions.methods) {
      Object.assign(vueOptions.methods, componentOptions.methods)

      delete componentOptions.methods
    }

    Object.assign(vueOptions, componentOptions)

    let vueInstance = new Vue(vueOptions)

    vueInstance.__ERROR_HANDLER = errorHandler

    return vueInstance
  }
}

export default vueTemplate