import dateFormat from 'dateformat'
import store from '@/store'

import evalExpression from '@/services/boolExEval.js'


const templateFunctions = {
  getDate(args, row) {
    // arguments are format, offset days, offset weeks, offset months
    let date = (args[0] != null && args[0] !== '') ? new Date(args[0]) : new Date()
    let ms = date.getTime()      
    ms += args[2] == null ? 0 : args[2] * 86400000 // 1 day = 86400000ms
    return dateFormat(new Date(ms), args[1]) 
  },
  surveyLink(args, row) {
    // arguments are 
    // 0: Link text
    // 1: custom domain
    // 2: database
    // 3: name
    // 4: end_date_offset
    
    // calculate end date
    let ms = new Date().getTime() + args[4] * 86400000 // 1 day = 86400000ms
    let  endDate = dateFormat(new Date(ms), 'isoDate')

    let paramObj = {
      id: row._id,
      linkText: args[0],
      customDomain: args[1],
      database: args[2],
      surveyName: args[3],
      endDate: endDate,
    }

    // $$surveyLink({"id":"o610409f59fdea4e980aabfaf","linkText":"Zur Befragung","customDomain":"https://dev-woenenn-node-server.herokuapp.com","database":"dev_Siemens_NextGen","surveyName":"survey1"})
    //console.log('$$surveyLink(' + JSON.stringify(paramObj) + ')')
    return '$$surveyLink(' + JSON.stringify(paramObj) + ')'
  },
  surveyUrl(args, row) {
    // arguments are 
    // 1: custom domain
    // 2: database
    // 3: name
    // 4: end_date_offset
    
    // calculate end date
    let ms = new Date().getTime() + args[3] * 86400000 // 1 day = 86400000ms
    let endDate = dateFormat(new Date(ms), 'isoDate')

    let paramObj = {
      id: row._id,
      customDomain: args[0],
      database: args[1],
      surveyName: args[2] || store.state.view.survey.name,
      endDate: endDate,
    }

    // $$surveyUrl({"id":"o610409f59fdea4e980aabfaf","customDomain":"https://dev-woenenn-node-server.herokuapp.com","database":"dev_Siemens_NextGen","surveyName":"survey1"})
    return '$$surveyUrl(' + JSON.stringify(paramObj) + ')'
  },
  iif(args, row) {
    // if-else expression. Example:
    // {{ iif("$row.casedata.business == 'Energy'"; "src='https://media-corbie.amazonaws.com'"; "")}}
    let condition = args[0];
    //condition = condition.replace(/(?<=\$row\.\S*)\./g, '-');
    let result;
    if (evalExpression(condition, {row: row})) {
      result = args[1];
    } else {
      result = args[2];
    }
    return result;
  }
}

export default templateFunctions