import { render, staticRenderFns } from "./QuestionSelect.vue?vue&type=template&id=175a85af&scoped=true"
import script from "./QuestionSelect.vue?vue&type=script&lang=js"
export * from "./QuestionSelect.vue?vue&type=script&lang=js"
import style0 from "./QuestionSelect.vue?vue&type=style&index=0&id=175a85af&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "175a85af",
  null
  
)

export default component.exports